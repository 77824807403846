@import 'variables';

:root {
    --breadcrumbs-background: #F1F1F1 !important;
    --h1-text-transform: var(--imported_h1_text_transform, uppercase);
    --h1-line-height: var(--imported_h1_line_height, 1);
    --product-card-box-shadow: none !important;

    @include desktop {
        --banner-block-item-height-col-2: 54rem !important;
        --banner-block-item-height-col-6: 25rem !important;
        --banner-item-title-font-size: 3rem !important;
        --banner-item-content-font-size: 1.6rem !important;
        --banner-item-content-line-height: 1.5 !important;
        --header-total-height: 0 !important;
        --h1-font-size: var(--imported_h1_font_size, 3rem);
        --paragraph-font-size: var(--imported_paragraph_font_size, 1.6rem);
        --paragraph-line-height: var(--imported_paragraph_line_height, 1.6) ;
    }
}

.color {
    color:#D1024B;
}

@include desktop {
    .MenuAdvanced {
        &-MainItemList {
            flex-wrap: wrap;
        }

        &-Link {
            color: #fff !important;
            font-weight: 400 !important;
        }
    }

    .BannerBlock {
        &-Item {
            &_type_column_6 {
                .BannerItem {
                    &-Title {
                        font-size: 1.4rem !important;
                    }
                }
            }
        }
    }
}

@include mobile {
    div.BannerGroup {
        &-Container {
            margin-top: 1.5rem !important;
        }
    }
   

    .BannerBlock {
        &-Item {
            &_type_column_6 {
                margin:  0 !important;

                .BannerItem {
                    &-Title {
                        font-size: 1.2rem !important;
                    }
                }
            }
        }
    }
}

.BannerBlock {
    &-Item {
        .BannerItem {
            &-Content {
                font-style: normal;
            }
        }
    }
}

.SaasSliderWidget {
    &-Heading {
        text-align: center;
        text-transform: uppercase !important;

        @include desktop {
            font-size: 3rem !important;
            line-height: 1.1 !important;
        }

        @include mobile {
            font-size: 2rem !important;
        }
    }
}

.SubcategoryList  {
    &-Item_noImage {
        h3 {
            line-height: 1 !important;
        }
    }
}

.Footer {
    &-Column {

        @include desktop {
            width: 25% !important;
        }

        &:nth-child(5),
        &:nth-child(6){
            display: none;
        }

        .FooterMenu {
            &-Link {
                &:hover {
                    color: #ccc;
                }
            }
        }
    }

    @include mobile {
        &-Content {
            min-height: auto !important;
        }
    }
}

.BannerSlider-Item  {
    .BannerItem {
        &-Link {
            background-color: #D1024B !important;
        }
    }
}

@include mobile {
    .BannerSlider-Item  {
        .BannerItem {
            &-Title {
                font-size: 2.5rem !important;
            }

            &-ContentBlock {
                --banner-slider-item-content-block-margin: 0 0 5rem 0 !important;
            }

            &-Content {
                --banner-item-content-font-size: 1.5rem !important;
                --banner-item-content-line-height: 2.1rem !important;
            }
        }
    }
}

.LayoutGroupWidget {
    &-Container {
        &-StaticBlocks {

            @include desktop {
                padding: 3em 0;
            }

            @include mobile {
                padding: 2em 1.5rem !important;
            }

            .AddressBlock {
                .CmsBlock-Wrapper {
                    display: flex;
                }

                .ImgBlock {
                    justify-content: center;
                    display: flex;
                    flex-direction: column;

                    img {
                        width: 100%;

                        @include desktop {
                            max-height: 17.5rem;
                        }

                        @include mobile {
                            height: auto !important;
                            min-width: 15rem;
                        }
                    }
                }

                .textBlock {
                    justify-content: center;
                    padding: 0 0 0 3rem;
                    display: flex;
                    flex-direction: column;
                }
            }

            .SeoBlock {
                border: 1px solid #e6e6e6;

                @include desktop {
                    padding: 4rem;
                }

                @include mobile {
                    padding: 2rem;
                }
                
                @include desktop {
                    h1 {
                        margin-bottom: 2rem;
                    }
                }

                @include mobile {
                    h1 {
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }
}

.ProductCard {
    border: 1px solid #e6e6e6;

    @include mobile {
        &-Picture {
            padding-bottom: 120% !important;
        }
    }
}

.BannerGroup-Container {
    .Banner {
        &Item {
            &-Title {
                line-height: 1.1;
                margin: 0;
                text-transform: uppercase;
            }
            
            &-ContentBlock {
                position: absolute;
                bottom: 0;
            }
        }
        
        &Block {    
            &-Item_type_column_3 {
                min-height: 0;
                height: auto;
                padding-bottom: 66%;
            }
        }
    }
}

@include mobile {
    .CategoryPage {
        &-Wrapper {
            margin-top:0 !important;
        }
    }
}

@include mobile {
    main {
        margin-top:0 !important;
    }
}

.CategoryPage {
    .swiper-wrapper {
        padding-bottom:0 !important; 
    }

    .swiper-button-next, .swiper-button-prev {
        top: 3.4rem !important;
    }
}