.BannerSlider {
    &-Item {
        --banner-slider-height: 40.5rem;

        height: var(--banner-slider-height) !important;

        .BannerItem {
            &-ContentBlock {
                --banner-slider-item-content-block-margin: 0 0 2rem 10rem;   
            }

            &-Title {
                --banner-slider-item-title-font-size: 5rem;
                font-style: italic;
                font-weight: 700;
                margin-bottom: 1.2rem;
                
            }

            &-Subtitle {
                --banner-item-subtitle-font-size: 1.8rem;
                margin-top: 2rem;
            }
            
            &-Content {
                --banner-item-content-font-size: 1.7rem;
                --banner-item-content-line-height: 2.8rem;
                font-weight: 300;
                margin-bottom: 1.4rem;
                
            }

            &-Link {
                --button-hover-padding: 2.5rem;
                --button-background: var(--primary-dark-color);
                --banner-item-link-border: var(--primary-dark-color);
                border-radius: 0.4rem;
            }
        }
    }
}