.Footer {
    --footer-column-content-color: var(--secondary-light-color);
    --footer-column-title-color: white;
    --footer-column-title-font-size: 2rem;
    --footer-column-title-font-weight: 700;
    --footer-column-title-text-transform: uppercase;
    --footer-content-background-color: var(--primary-base-color);

    @include mobile {
        --footer-column-content-background-color: #002756;
        --footer-column-title-font-size: 1.6rem;
        --footer-column-title-text-transform: none;
        --footer-column-newsletter-background-color: var(--secondary-base-color);
    }
    background: darken(#003e89, 10%);

    & &-Content {
        background-color: var(--primary-base-color);
    }
}