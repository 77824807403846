.BannerBlock {
    &-Item {
        &_type_column_6 {
            --banner-block-item-height-col-6: 22rem;
            border: 1px solid #e6e6e6;

            .BannerItem {
                &-ContentBlock {
                    background-color: white;
                    width: 100%;
                }

                &-Image {
                    max-height: 20rem;
                }

                &-Title {
                    --banner-item-title-font-size-small: 1.2rem;
                    text-align: center;
                    width: 100%;
                    color: black;
                    font-style: normal !important;
                     
                }
            }
        }
        
    }

}