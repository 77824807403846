/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../pwasaas/src/style/abstract/variables';
@import '../../../../pwasaas/src/style/abstract/media';
@import '../../../../pwasaas/src/style/abstract/button';
@import '../../../../pwasaas/src/style/abstract/loader';
@import '../../../../pwasaas/src/style/abstract/icons';
@import '../../../../pwasaas/src/style/abstract/image';
@import '../../../../pwasaas/src/style/abstract/parts';

@import '../pwasaas/src/style/abstract/abstract';

@import '../pwasaas/src/style/base/breakpoints';
@import '../pwasaas/src/style/base/keyframes';
@import '../pwasaas/src/style/base/root';
@import '../pwasaas/src/style/base/reset';
@import '../pwasaas/src/style/base/button';
@import '../pwasaas/src/style/base/list';
@import '../pwasaas/src/style/base/link';
@import '../pwasaas/src/style/base/table';
@import '../pwasaas/src/style/base/input';
@import '../pwasaas/src/style/base/fieldset';
@import '../pwasaas/src/style/base/blockquote';
@import '../pwasaas/src/style/base/usp';

@import '../pwasaas/src/style/cms/block';

@import '../../theme/style/main';

.CategoryFilterOverlay-Filter_type_price {
    display: none;
}